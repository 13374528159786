import React from "react"
import { Link } from "gatsby"
import SEO from "../components/Seo"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Container, Row, Column } from "../components/Grid"
import Hero from "../components/Hero"
import Image from "../components/Image"
import Resource from "../components/Resource"
import FooterCallout from "../partials/footerCallout"
import { confrontingComparisons } from "../partials/familyActivities"

class FamilyResourcesPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Family Resources" />
        {/***************  HERO ***************/}
        <Section className="hero hero__family-resources">
          <Hero
            title={
              <>
                <span className={"header-bar--yellow"}>Family</span>
                <span className={"header-bar--yellow"}>Resources</span>
              </>
            }
            body="Teach your child to be their best self."
            image="img-family-resources-lead.png"
          />
        </Section>
        {/***************  ABOVE FOOTER CALLOUT ***************/}
        <Section className="">
          <Container>
            <p>
              Parents and caregivers have a huge influence on their student’s
              lives. Support your student(s) at home with resources that focus
              on confidence-building, self-esteem, and positive body image.
            </p>
          </Container>
        </Section>

        {/***************  ACTIVITIES ***************/}
        <Section
          className={"activities family__activities lightgray-background"}
        >
          <Container>
            <Row>
              {confrontingComparisons.map((item, i) => (
                <Column span={4} key={i}>
                  <Resource label="FAMILY ACTIVITY" {...item} inGrid />
                </Column>
              ))}
            </Row>
          </Container>
        </Section>

        {/***************  EXPLORE ADDITIONAL RESOURCES ***************/}
        <Section className={"squiggle"}>
          <Container>
            <h3>Download our Resources</h3>
            <Row>
              <Column span={5} className={"is-offset-1 resource"}>
                <Image
                  className={"resource-image"}
                  filename={"img-promo-vft.png"}
                />
                <h4 className={"bold-text"}>Virtual Field Trip</h4>
                <p>
                  Help students learn to appreciate their bodies, get smarter
                  about images they see online, and build strategies to fight
                  weight-based bullying and teasing.
                </p>
                <Link to={"/virtual-field-trip"}>Learn More</Link>
              </Column>
              <Column span={5} className={"resource"}>
                <Image
                  className={"resource-image"}
                  filename={"img-promo-resources.png"}
                />
                <h4 className={"bold-text"}>Classroom Resources</h4>
                <p>
                  Help your students realize their full potential with NEW
                  classroom resources.
                </p>
                <Link to={"/classroom-resources"}>Learn More</Link>
              </Column>
            </Row>
          </Container>
        </Section>

        {/***************  ABOVE FOOTER CALLOUT ***************/}
        <Section className="midgray-background body-confidence-fact body-confidence-fact--family-resources">
          <Container>
            <Row>
              <Column span={7}>
                <h3>Fact:</h3>
                <p>
                  People are more likely to do unhealthy things to control their
                  weight and body shape when they focus more on appearance than
                  body functionality.
                </p>

                <p>
                  -<em>Thompson & Stice, 2001</em>
                </p>
              </Column>
              <Column
                span={4}
                className={"is-offset-1 body-confidence-fact__container"}
              >
                <div className={"body-confidence-fact__container__image"}>
                  <Image filename={"img-subfooter-4.png"} className={""} />
                </div>
              </Column>
            </Row>
          </Container>
        </Section>

        {/***************  FOOTER CALLOUT ***************/}
        <FooterCallout />
      </Layout>
    )
  }
}

export default FamilyResourcesPage
